import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Button, Carousel, GatsbyLink } from "components";
import { ImagePass } from "../Image/ImagePass";
import { ReactComponent as Arrow } from "images/icons/arrow.svg";
import {
  decodeEntities,
  getCategory,
  getServiceCat,
} from "../../utils/helpers";
import RenderContent from "../RenderContent";
import "./RelatedProjects.scss";

const RelatedProjectComp = ({
  data,
  title,
  backgroundColour,
  fullWidth,
  postfilter,
}) => {
  // if no blog posts have been selected then return nothing
  if (!postfilter) {
    return null;
  }

  const {
    allWordpressWpProjects: { edges: posts },
    allWordpressWpProjectCategory: { edges: categories },
  } = data;

  // creates an array of the selected posts in WP
  const filterItemsArray = postfilter.map(function (obj) {
    return obj.wordpress_id;
  });

  // filters through all projects and returns the ones which match the filterItemsArray
  const filteredResults = posts.filter((post) =>
    filterItemsArray.includes(post.node.wordpress_id)
  );

  const blogFeedLink = {
    url: `/projects/`,
    title: "View all projects",
  };

  const carouselOptions = {
    perView: fullWidth ? 4 : 3,
    type: "slider",
    gap: fullWidth ? 16 : 130,
    swipeThreshold: true,
    breakpoints: {
      1080: {
        perView: 2,
        gap: 90,
      },
      700: {
        perView: 1,
        gap: 30,
      },
    },
  };

  return (
    <section className={`project-feed layout ${backgroundColour}`}>
      <div className="project-feed-container">
        <div className="project-feed-header">
          <div>
            <p className="eyebrow">Case studies</p>
            <h2 className="heading">{title}</h2>
          </div>
          {blogFeedLink && (
            <div className="button-contianer sm-hide">
              <Button
                to={blogFeedLink.url}
                theme={backgroundColour === "white" ? "light" : "dark"}
              >
                {blogFeedLink.title}
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className={`carousel-container ${fullWidth ? "full-width" : false}`}>
        <Carousel
          options={carouselOptions}
          id="related-projects"
          theme={backgroundColour}
          fullWidth={fullWidth ? fullWidth : false}
        >
          {Object.keys(filteredResults).length > 0 &&
            filteredResults?.map((post, index) => {
              const postNode = post?.node;
              const serviceCategory = getServiceCat(postNode.project_category);
              return (
                <GatsbyLink to={postNode.path} className="project" key={index}>
                  <div className="image-container">
                    <ImagePass src={postNode.featured_media} />
                  </div>
                  <span className="eyebrow">
                    {postNode.project_category &&
                      getCategory(serviceCategory, categories)}
                  </span>

                  <h3 className="project-heading">
                    {decodeEntities(postNode.title)}
                  </h3>

                  <RenderContent content={postNode.excerpt} />
                  <span className="arrow">
                    <Arrow />
                  </span>
                </GatsbyLink>
              );
            })}
        </Carousel>
      </div>

      <div className="project-feed-container">
        {blogFeedLink && (
          <div className="button-contianer lg-hide">
            <Button
              to={blogFeedLink.url}
              theme={backgroundColour === "white" ? "light" : "dark"}
            >
              {blogFeedLink.title}
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

const RelatedProjects = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          wordpressWpSettings {
            blogSlug
          }
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              showAuthor
            }
          }
          allWordpressWpProjects {
            edges {
              node {
                ...ProjectListFields
              }
            }
          }
          allWordpressWpProjectCategory {
            edges {
              node {
                acf {
                  title
                }
                wordpress_id
              }
            }
          }
        }
      `}
      render={(data) => <RelatedProjectComp {...props} data={data} />}
    />
  );
};

export default RelatedProjects;

RelatedProjects.defaultProps = {
  title: "Related Projects",
  backgroundColour: "white",
  fullWidth: false,
};
